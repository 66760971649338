<template>
  <div class="container login-container">
    <!-- mobile -->
    <div
      v-if="isMobile"
      class="d-flex align-items-center justify-content-center"
      style="height: 100%"
    >
      <div class="text-center">
        <img
          src="img/rw/rw.png"
          alt="rumahweb"
          class="img-fluid my-2"
          style="width: 100px"
        />
        <h4 class="my-3">Rumahweb Messenger</h4>
        <p class="">You cannot open this page with mobile version.</p>
      </div>
    </div>

    <!-- browser -->
    <div class="row" v-if="!isMobile">
      <div class="col-sm-6 leftBox">
        <div
          class="d-flex align-items-center justify-content-center flex-column"
          style="height: 100%"
        >
          <!-- <h1 class="text-uppercase font-weight-bold">Rumahweb</h1>
          <h3 class="text-uppercase font-weight-bold">Messenger</h3>-->
          <a href="javascript:void(0)" @click="onSubmit()">
            <img
              src="img/rw/google_sign_in.png"
              alt="Google"
              class="img-fluid"
              style="width: 62%"
            />
          </a>
        </div>
      </div>
      <div class="col-sm-6 login-form">
        <div class="text-center my-4">
          <img
            src="img/svg/rumahweb.svg"
            alt="rumahweb"
            class="img-fluid"
            style="width: 150px"
          />
        </div>
        <form @submit.prevent.stop="onSubmit()">
          <div class="form-group">
            <input
              type="email"
              class="form-control"
              :class="checkField('email')"
              v-model="$v.form.email.$model"
              placeholder="Your email"
            />
          </div>
          <div class="form-group input-group">
            <input
              type="password"
              class="form-control"
              :class="checkField('password')"
              v-model="$v.form.password.$model"
              placeholder="Your password"
            />
          </div>
          <div class="form-group">
            <button
              type="submit"
              class="btn btn-outline-success btn-lg btn-block"
            >
              Sign In
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import { loader } from "@/common/mixins/mix_loader";
import { staffCookies } from "@/common/mixins/mix_cookies";
import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";

export default {
  name: "Auth.vue",
  mixins: [loader, staffCookies, validationMixin],
  data() {
    return {
      isMobile: false,
      form: {
        email: "addin@rw.com",
        password: "4dd1n",
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
        minLength: minLength(6),
      },
      password: {
        required,
        minLength: minLength(5),
      },
    },
  },
  methods: {
    onSubmit: function () {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.loading();
      setTimeout(() => {
        this.$store
          .dispatch("staffLogin", this.form)
          .then((staff) => {
            this.cookies.add({
              isLogin: true,
              staff: {
                id: staff.id,
                name: staff.name,
                email: staff.email,
                jabatan: staff.jabatan,
                departement: staff.departement,
              },
            });
            this.$router.push("/");
          })
          .catch((err) => {
            this.$swal("error", err, "error");
          })
          .finally(() => {
            this.loading(false);
          });
      }, 700);
    },
    checkField: function (field) {
      if (this.$v.form[field].$error) {
        if (this.$v.form[field].$invalid) {
          return "is-invalid";
        }
      }
      if (!this.$v.form[field].$invalid) {
        return "is-valid";
      }
    },
  },
  mounted() {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  },
};
</script>

<style scoped>
.login-container {
  margin-top: 7%;
  border: 1px solid #ccd1d1;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  max-width: 50%;
}

.leftBox {
  background-color: #68c8b7;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: #fff;
  padding: 15px;
  text-align: center;
}

@media screen and (max-width: 720px) {
  .login-container {
    max-width: 90%;
    box-shadow: none;
    height: 100vh;
    margin-top: 0;
    border: none;
  }
}
</style>
